import React from 'react';
import Checkbox from './CustomCheckbox';
import styled from 'react-emotion';

export const Radio = styled(Checkbox)`
  display: flex;
  margin-top: 1rem;
`;
const Label = styled('span')`
  font-weight: 600;
`;
export const CheckboxWrapper = styled('div')``;

const CheckboxGroup = ({
  selectedItem,
  items,
  handleChange,
  groupLabel,
  className
}) => {
  const englishLangIndex = items.findIndex(item => item.val === 'English');
  const langItems =
    englishLangIndex !== -1 ? items.splice(englishLangIndex, 1) : [];

  return React.createElement(
    CheckboxWrapper,
    { className: className },
    groupLabel && React.createElement(Label, null, groupLabel),
    [...langItems, ...items].map(item =>
      item && item.val
        ? React.createElement(Radio, {
            type: 'radio',
            key: item.val,
            value: item.val,
            tabIndex: 0,
            checked: selectedItem === item.val ? true : false,
            label: item.label,
            onChange: () => handleChange(item.val)
          })
        : null
    )
  );
};
export default CheckboxGroup;
