import React from 'react';
import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const SecondaryStyle = () => css`
  background-color: white;
  border-color: black;
  color: black;
`;

const disabledStyle = () => css`
  background: #f7f7f7;
  border: 0.75px solid #dedede;
  color: #808080;
`;

const TrendButtonStyle = ({ secondary, theme, disabled }) => css`
  background-color: #2f80ed;
  border-color: #2f80ed;
  color: white;
  font-size: 16px;
  ${theme.above.md} {
    max-width: 100%;
  }

  ${secondary && SecondaryStyle()};
  ${disabled && disabledStyle()}
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const ButtonWithLoading = ({ loading, loadingText, ...props }) =>
  loading ? (
    <Button>
      {loadingText}
      <Spinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
