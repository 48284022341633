import { Below } from '@jetshop/ui/Breakpoints';
import { SearchField } from '@jetshop/ui/Search';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled, { css } from 'react-emotion';
import MainNav from './MainNav';
import TopBar from './TopBar';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';

import MaxWidth from '../MaxWidth';
import MainNavMobile from './MainNavMobile';

const StyledMaxWidth = styled(MaxWidth)`
  position: relative;
`;

const MenuActiveStyle = css`
  background: #fff;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 0.3s;
`;

const HeaderWrapper = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: transparent;
  ${MenuActiveStyle}

  ${({ theme }) => theme.above.lg} {
    .start & {
      background: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
`;

const Container = styled('div')`
  position: relative;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  ${theme('below.lg')} {
    height: 64px;
  }
`;

const StyledSearchField = styled(SearchField)`
  position: absolute;
  top: 70px;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);
  ${theme('below.sm')} {
    width: 100%;
  }
  ${SearchField.Wrapper} {
    width: 286px;
    //display: flex;
    ${theme('below.sm')} {
      width: 100%;
    }
    input {
      width: calc(100% - 60px);
      height: 100%;
      flex: 1 1 auto;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 0;
      font-size: 1rem;
      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
  ${SearchField.Cancel} {
    position: absolute;
    right: 24px;
    //top: 13px;
    //height: 100%;
    height: 20px;
    top: 18px;
    ${theme('below.sm')} {
    }
  }
  ${SearchField.Flyout} {
    position: relative;
    top: 0;
    border: none;
    text-align: left;
    ${theme('below.sm')} {
    }
    > div {
      //padding: 0.5rem;
      > div {
        padding: ${({ theme }) => `${theme.space[1]} ${theme.space[3]}`};
      }
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }

    li {
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
      background: ${({ theme }) => theme.colors.themeGrey};
    }

    a {
      display: block;
      font-size: 0.875rem;
    }
  }
`;

const Header = props => {
  const [searchOpen, setSearchOpen] = React.useState(false);

  const toggleSetSearchOpen = () => setSearchOpen(s => !s);
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (pathname === '/') {
        document.body.classList.add('start');
        document.body.classList.add('header-no-margin');
      } else {
        document.body.classList.remove('start');
        document.body.classList.remove('header-no-margin');
      }

      if (searchOpen) {
        setTimeout(() => {
          const inputFieldVisible = document.querySelector(
            'input[type="search"]'
          );
          if (inputFieldVisible) {
            inputFieldVisible.focus();
            document.onkeydown = evt => {
              evt = evt || window.event;
              var isEscape = false;
              if ('key' in evt) {
                isEscape = evt.key === 'Escape' || evt.key === 'Esc';
              } else {
                isEscape = evt.keyCode === 27;
              }
              if (isEscape) {
                setSearchOpen(false);
              }
            };
          }
        }, 100);
      }
    }
  }, [pathname, searchOpen]);

  return (
    <HeaderWrapper>
      <TopBar />
      <StyledMaxWidth>
        <Container>
          {searchOpen && (
            <StyledSearchField
              onCancel={() => setSearchOpen(false)}
              onSubmit={() => setSearchOpen(false)}
            />
          )}
          <Below breakpoint="lg">
            {matches =>
              matches ? (
                <MainNavMobile
                  result={props.result}
                  setSearchOpen={toggleSetSearchOpen}
                  renderMobile={true}
                />
              ) : (
                <MainNav
                  setSearchOpen={toggleSetSearchOpen}
                  searchIsOpen={toggleSetSearchOpen}
                  categories={props.result}
                />
              )
            }
          </Below>
        </Container>
      </StyledMaxWidth>
    </HeaderWrapper>
  );
};

export default _props => (
  <Below breakpoint="md">
    {matches =>
      matches ? (
        <Query variables={{ levels: 2 }} query={homeCategoriesQuery}>
          {result => <Header result={result} />}
        </Query>
      ) : (
        <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
          {result => <Header result={result} />}
        </Query>
      )
    }
  </Below>
);
