import { useLocation } from 'react-router-dom';
import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled, { css } from 'react-emotion';
import t from 'format-message';
import SubMenuWrapper from './SubMenuWrapper';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import * as NavRoutes from './MenuRoutes';

const Wrapper = styled('div')`
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  padding: 1.25rem 0;
  transition: padding ease-in-out 0.4s;

  a {
    display: inline-block;
    position: relative;
    margin: 0 0.75rem;
    color: ${({ theme }) => theme.colors.themeBlack};
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: bold;

    ${({ theme }) => theme.above.md} {
      .start & {
        color: #fff;
        &:after {
          background: #fff;
        }
      }
    }

    @media screen and (max-width: 1050px) {
      font-size: 0.875rem;
      margin: 0 0.5rem;
    }

    &:after {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: ${({ theme }) => theme.colors.themeBlack};
      transition: width ease-in-out 0.2s;
    }
    &.active,
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
`;

const FloatingBackground = styled('div')`
  ${({ theme }) => theme.elements.fwContainer}
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background: #000;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
`;

const ToggleStyle = props => css`
  ${MainMenuItem} {
    transition: padding ease-in-out 0.3s;
  }
  ${props.hoverMenu &&
    `
    ${MainMenuItem} {
      padding: 2rem 0rem;
    }
    &~${FloatingBackground} {
      &:after {
        height: 100vw;
        opacity: .5;
        -webkit-box-shadow: 0px 23px 15px 2px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 23px 15px 2px rgba(0,0,0,0.5);
        box-shadow: 0px 23px 15px 2px rgba(0,0,0,0.5);
      }
    }
  `}
`;

//let timeoutOnMouseOver = false;

const ThemeMenuWrapper = styled('div')`
  ${ToggleStyle}
`;

const MenuContainer = props => {
  const { selectedChannel } = React.useContext(ChannelContext);
  const { pathname } = useLocation();
  let catRef = null;
  let queryData = null;
  // let aboutPage = null;
  const [hoverMenu, setHoverMenu] = React.useState(false);
  const [activeCat, setActiveCat] = React.useState('');
  const isValidPathname =
    pathname === '/sulman' ||
    pathname === '/alpina' ||
    pathname === '/sulmanin';

  const menuHoverShow = () => {
    setHoverMenu(true);

    if (typeof window !== 'undefined') {
      if (pathname === '/') {
        document.body.classList.remove('start');
      }
    }
  };

  const menuHoverHide = () => {
    setHoverMenu(false);

    if (typeof window !== 'undefined') {
      if (pathname === '/') {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;

        if (scrollTop === 0) {
          document.body.classList.add('start');
        } else {
          document.body.classList.remove('start');
        }
      } else {
        document.body.classList.remove('start');
        document.body.classList.remove('header-no-margin');
      }
    }
  };

  const catLinkHandleMouseEnter = (cat, setActiveCategory) => {
    if (activeCat === '') {
      setActiveCategory(cat);
      menuHoverShow();
    } else {
      const ref = setTimeout(() => {
        setActiveCategory(cat);
        menuHoverShow();
      }, 250);

      catRef = ref;
    }
    setTimeout(() => {
      setActiveCat(cat);
    }, 250);
  };

  const catLinkHandleMouseLeave = () => {
    clearTimeout(catRef);
  };

  // const menuMouseEnter = () => {
  //   clearTimeout(ref);
  // };

  const menuMouseLeave = clearActive => {
    clearActive();
  };

  // const queryData = props.queryData;

  if (selectedChannel.id === 1) {
    queryData = NavRoutes.routeSE;
    // aboutPage = '/om-oss';
  }

  if (selectedChannel.id === 2) {
    queryData = NavRoutes.routeFI;
    // aboutPage = '/meista';
  }

  return (
    <>
      <ThemeMenuWrapper hoverMenu={hoverMenu} onMouseLeave={menuHoverHide}>
        <UIMenuContainer>
          {props => (
            // When mousing outside the menu, close it
            // onMouseLeave={props.clearActiveCategories}
            <Wrapper
              onMouseLeave={() => menuMouseLeave(props.clearActiveCategories)}
            >
              <ul>
                {queryData && queryData
                  ? queryData.map(cat => (
                      <MainMenuItem
                        key={cat.id}
                        // When mousing over a menu item, set it as the active nav
                        //() => props.setActiveCategory(cat)
                      >
                        <CategoryLink
                          // When following a category link, close the menu
                          className={
                            cat.id === 'varumarken' && isValidPathname
                              ? 'active'
                              : null
                          }
                          onClick={_event => {
                            if (cat.id === 'varumarken') {
                              _event.preventDefault();
                            } else {
                              props.clearActiveCategories();
                            }
                          }}
                          category={cat}
                          onMouseEnter={_event => {
                            if (cat.hasSubcategories) {
                              catLinkHandleMouseEnter(
                                cat,
                                props.setActiveCategory
                              );
                            } else {
                              catRef = setTimeout(() => {
                                props.setActiveCategory('');
                              }, 250);
                            }
                          }}
                          onMouseLeave={() => catLinkHandleMouseLeave()}
                        >
                          {t(cat.name)}
                        </CategoryLink>
                      </MainMenuItem>
                    ))
                  : null}
                {/* <MainMenuItem
                  key="om-oss"
                  onMouseEnter={_event => {
                    props.setActiveCategory('');
                  }}
                  onMouseLeave={() => catLinkHandleMouseLeave()}
                >
                  <NavLink activeClassName="active" to={aboutPage}>
                    {t('About us')}
                  </NavLink>
                </MainMenuItem> */}
              </ul>

              {props.activeCategories.length > 0 &&
                props.activeCategories[0].subcategories &&
                props.activeCategories[0].subcategories.length > 0 && (
                  <SubMenuWrapper
                    pose={
                      props.activeCategories[0].subcategories.length > 0
                        ? 'open'
                        : 'closed'
                    }
                    activeCategory={props.activeCategories[0]}
                    closeNav={props.clearActiveCategories}
                    hideMenuBackground={menuHoverHide}
                    setActiveCategory={props.setActiveCategory}
                  />
                )}
            </Wrapper>
          )}
        </UIMenuContainer>
      </ThemeMenuWrapper>
      <FloatingBackground />
    </>
  );
};

export default MenuContainer;
