import React from 'react';
import styled from 'react-emotion';
import CountryListItem from './ChannelListItem';
const Channels = ({ channels, selectedChannel, onSelect }) => {
  return channels
    ? React.createElement(
        ChannelList,
        null,
        channels.map(channel => {
          if (channel.id === 3) {
            return null;
          }

          return React.createElement(CountryListItem, {
            channel: channel,
            key: channel.defaultLanguage.name,
            isSelected: channel.name === selectedChannel.name,
            onSelect: onSelect
          });
        })
      )
    : null;
};
export default Channels;
export const ChannelList = styled('ul')`
  margin: 0;
  padding: 0;
`;

