import React, { Fragment } from 'react';
import styled from 'react-emotion';
import { Price } from '@jetshop/ui/Price';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      // eslint-disable-next-line
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++)
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    return t;
  };

const Wrapper = styled('div')``;
const NormalPrice = styled('div')``;
const OldPrice = styled('div')`
  text-decoration: line-through;
`;
const NewPrice = styled('div')``;
const PriceComponent = _a => {
  const freightCost = _a.freightCost ? _a.freightCost : 0;
  var { className, formatter = t.number } = _a,
    props = __rest(_a, ['className', 'formatter']);
  return React.createElement(
    ChannelContext.Consumer,
    null,
    ({ selectedChannel }) =>
      React.createElement(
        Wrapper,
        { className: className },
        React.createElement(
          Price,
          Object.assign({}, props, {
            includeVat: selectedChannel.settings.pricesIncVat
          }),
          pricing => {
            return pricing.hasDiscount
              ? React.createElement(
                  Fragment,
                  null,
                  React.createElement(
                    NewPrice,
                    null,
                    formatter(
                      pricing.price.value,
                      selectedChannel.currency.name,
                      selectedChannel.currency.format.culture
                    )
                  ),
                  React.createElement(
                    OldPrice,
                    null,
                    formatter(
                      pricing.previousPrice.value,
                      selectedChannel.currency.name,
                      selectedChannel.currency.format.culture
                    )
                  )
                )
              : React.createElement(
                  NormalPrice,
                  null,
                  formatter(
                    pricing.price.value + freightCost,
                    selectedChannel.currency.name,
                    selectedChannel.currency.format.culture
                  )
                );
          }
        )
      )
  );
};
PriceComponent.Wrapper = Wrapper;
PriceComponent.Normal = NormalPrice;
PriceComponent.Old = OldPrice;
PriceComponent.New = NewPrice;
export default PriceComponent;

