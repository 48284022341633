import React from 'react';
import styled from 'react-emotion';

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      /* eslint no-redeclare: 0 */
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(p[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]];
      }
    return t;
  };

const CheckInput = styled('input')`
  /* hide browser default checkbox */
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
const CheckBox = styled('span')`
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 50%;
`;

const Label = styled('label')`
  display: flex;
  position: relative;
  height: 22px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;

  input:checked ~ .checkbox-box {
    svg {
      display: block;
    }
  }

  input ~ .checkbox-box {
    border: 1px solid #000;
  }

  input:checked {
    & ~ .checkbox-box {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: #000;
      }
    }

    & ~ .checkbox-lang-name {
      font-weight: 700;
    }
  }
`;
const Checkbox = _a => {
  var { label } = _a,
    props = __rest(_a, ['label']);
  return React.createElement(
    Label,
    { className: props.className, 'data-testid': `checkbox-${label}` },
    React.createElement(CheckInput, Object.assign({ type: 'checkbox' }, props)),
    React.createElement(CheckBox, { className: 'checkbox-box' }, null),
    React.createElement('span', { className: 'checkbox-lang-name' }, label)
  );
};
Checkbox.Label = Label;
export default Checkbox;

