import DecrementQuantity from '@jetshop/core/components/Mutation/DecrementQuantity';
import IncrementQuantity from '@jetshop/core/components/Mutation/IncrementQuantity';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
//import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';

const Wrapper = styled('div')`
  //display: table-row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.colors.themeGrey};
  &:last-of-type {
    border: none;
  }
  /* &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.themeGrey};
  } */
`;

const ProductName = styled('span')`
  text-transform: uppercase;
  font-weight: normal;
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  text-align: left;
  ${({ theme }) => theme.below.sm} {
    margin-top: 0.5rem;
  }
`;

const AdjustQty = styled('div')`
  display: flex;
  align-items: center;
  button {
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.themeGreyMedium};
    color: black;
    line-height: 1rem;
    border-radius: 50%;

    &:first-of-type {
      margin-right: 0.35rem;
    }

    &:last-of-type {
      margin-left: 0.35rem;
    }

    :focus {
      outline: none;
    }
    ${({ theme }) => theme.below.sm} {
      display: none;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 34px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.themeGreyMedium};
    ${({ theme }) => theme.below.sm} {
      width: 47px;
      height: 28px;
    }
  }
`;

const RemoveItem = styled('div')`
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }
  svg {
    height: 17px;
    width: 17px;
  }
`;

const TD = styled('div')`
  //display: table-cell;
  //vertical-align: top;
  padding: ${({ theme }) => theme.space[2]} 0;
  &:first-of-type {
    padding-left: ${({ theme }) => theme.space[5]};
    ${({ theme }) => theme.below.sm} {
      padding-left: ${({ theme }) => theme.space[2]};
    }
  }
  &:last-of-type {
    padding-right: ${({ theme }) => theme.space[5]};
    ${({ theme }) => theme.below.sm} {
      padding-right: ${({ theme }) => theme.space[2]};
    }
  }
`;

const ColImage = styled(TD)`
  width: 25%;
  > div {
    width: 100px;
    height: 100px;
    padding-top: 0;
  }
`;

const ColDetails = styled(TD)`
  width: 15%;
`;

const ColRemove = styled(TD)`
  width: 5%;
`;

const ColQty = styled(TD)`
  width: 15%;
`;

const ColPrice = styled(TD)`
  width: 15%;
  ${({ theme }) => theme.below.sm} {
    padding: 0 !important;
  }
`;
const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  return (
    <Wrapper className={className}>
      <ColImage>
        {item.product.images.length > 0 && (
          <Image
            aspect="1:1"
            sizes="7rem"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
          />
        )}
      </ColImage>

      <ColDetails>
        <ItemDetails>
          <ProductName>
            <h2>{item.product.name}</h2>
            {isVariant && (
              <h3
                className={css`
                  margin-top: 0.5rem;
                `}
              >
                {variantValues.join(', ')}
              </h3>
            )}
          </ProductName>
          <Above breakpoint="md">
            {matches =>
              matches || (
                <ColPrice>
                  <LinePrice price={item.total} />
                </ColPrice>
              )
            }
          </Above>
        </ItemDetails>
      </ColDetails>

      <ColQty>
        <AdjustQty>
          <DecrementQuantity>
            {decrementQuantity => (
              <button
                disabled={item.quantity === 1}
                onClick={() =>
                  item.quantity !== 1 && decrementQuantity(item.id)
                }
              >
                -
              </button>
            )}
          </DecrementQuantity>
          <span>{item.quantity}</span>
          <IncrementQuantity>
            {incrementQuantity => (
              <button onClick={() => incrementQuantity(item.id)}>+</button>
            )}
          </IncrementQuantity>
        </AdjustQty>
      </ColQty>

      <Above breakpoint="md">
        {matches =>
          matches && (
            <ColPrice>
              <LinePrice price={item.total} />
            </ColPrice>
          )
        }
      </Above>

      <ColRemove>
        <RemoveItem>
          <RemoveFromCart>
            {removeFromCart => (
              <button
                onClick={() => removeFromCart(item.id, item.product)}
                data-testid="remove-from-cart"
              >
                <Cross />
              </button>
            )}
          </RemoveFromCart>
        </RemoveItem>
      </ColRemove>
    </Wrapper>
  );
};

export default CartItem;
