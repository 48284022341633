import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { ReactComponent as CartSVG } from '../../svg/Cart.svg';
//import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  :hover {
    color: ${theme('colors.black')};
  }
`;

// const CartLabel = styled('label')`
//   display: block;
//   font-size: 12px;
//   color: #000;
//   margin-top: 3px;
// `;

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }
`;

const CartCounter = styled('span')`
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #000;
  ${({ theme }) => theme.above.md} {
    .start & {
      color: #fff;
    }
  }
`;

const CartIcon = styled(CartSVG)`
  width: 21px;
  height: 24px;
  ${({ theme }) => theme.above.md} {
    .start & {
      fill: #fff;
    }
  }
`;

const CustomCartButton = styled('div')`
  position: relative;
`;

class CartButton extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <ToastContainerWrapper>
          <StyledToastContainer
            toastClassName={css`
              padding: 0px;
              cursor: auto;
              font-family: inherit;
            `}
            autoClose={false}
            hideProgressBar
            closeButton={false}
            closeOnClick={false}
          />
        </ToastContainerWrapper>
        <CartProvider query={cartQuery}>
          {result => {
            // Set items in cart to the API result.
            // If the result is undefined, fall back to 0
            const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

            return (
              // Use a flyout for breakpoints above md
              // Else use a Drawer
              <Above breakpoint="md">
                {matches =>
                  matches ? (
                    <FlyoutTrigger id="cart-flyout">
                      {flyout => (
                        <Button
                          data-testid="header-cart"
                          onClick={
                            flyout.isOpen
                              ? flyout.hideTarget
                              : flyout.showTarget
                          }
                        >
                          <CustomCartButton>
                            <CartIcon />
                            <CartCounter>{itemsInCart}</CartCounter>
                          </CustomCartButton>
                        </Button>
                      )}
                    </FlyoutTrigger>
                  ) : (
                    <DrawerTrigger preventOverflow={true} id="cart-drawer">
                      {drawer => (
                        <Button
                          data-testid="header-cart"
                          onClick={
                            drawer.isOpen
                              ? drawer.hideTarget
                              : drawer.showTarget
                          }
                        >
                          <CustomCartButton>
                            <CartIcon />
                            <CartCounter>{itemsInCart}</CartCounter>
                          </CustomCartButton>
                        </Button>
                      )}
                    </DrawerTrigger>
                  )
                }
              </Above>
            );
          }}
        </CartProvider>
      </Fragment>
    );
  }
}

export default CartButton;
