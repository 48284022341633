import React, { Component } from 'react';
import styled from 'react-emotion';
import ItemsUSP from '../../Theme/ItemsUSP';
import MaxWidth from '../MaxWidth';

const StyledMaxWidth = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Bar = styled('div')`
  background: ${({ theme }) => theme.colors.themeBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //height: 50px;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.2s cubic-bezier(0.55, 1, 0.55, 1);
  ${({ theme }) => theme.below.md} {
    //justify-content: space-between;
    //height: 40px;
  }
  ${({ hide }) =>
    hide &&
    `
    max-height: 0px;
    `};

  .af-login {
    position: absolute;
    left: 2rem;

    li {
      &::marker {
        content: none;
      }

      a {
        color: white;
        font-size: 14px;
      }
    }

    ${({ theme }) => theme.below.md} {
      display: none;
    }
  }
`;

class TopBar extends Component {
  state = {
    hideTopbar: false
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const windowPath = window.location.pathname;

    setTimeout(() => {
      if (typeof window !== 'undefined') {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;

        if (scrollTop > 0 && this.state.hideTopbar === false) {
          this.setState({ hideTopbar: true });

          if (windowPath === '/') {
            document.body.classList.remove('start');
            document.body.classList.remove('header-no-margin');
          }
          document.body.classList.remove('mininav-height');
        } else if (scrollTop === 0 && this.state.hideTopbar === true) {
          this.setState({ hideTopbar: false });

          if (windowPath === '/') {
            document.body.classList.add('start');
            document.body.classList.add('header-no-margin');
          }
          document.body.classList.add('mininav-height');
        }
      }
    }, 200);
  };

  render() {
    return (
      <Bar hide={this.state.hideTopbar} onScroll={this.handleScroll}>
        <StyledMaxWidth>
          <div className="af-login">
            <li>
              <a
                href="https://e-line.lindstromnilsson.se/sv/Login"
                target="_blank"
                rel="noopener noreferrer"
              >
                ÅF login
              </a>
            </li>
          </div>
          <ItemsUSP />
        </StyledMaxWidth>
      </Bar>
    );
  }
}

export default TopBar;
