import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { css } from 'emotion';
import React, { Fragment } from 'react';
import Selector from './Selector';
import styled from 'react-emotion';

import { ReactComponent as CarrotSVG } from '../../../svg/Carrot.svg';

const CarrotIcon = styled(CarrotSVG)`
  width: 14px;
  height: 8px;
  margin-left: 4px;
  align-self: center;
  ${({ theme }) => theme.above.md} {
    .start & {
      fill: #fff;
    }
  }
`;

const Flyout = ({ children }) => (
  <div
    className={css`
      background-color: white;
      position: absolute;
      z-index: 5;
      right: 0;
      top: calc(100% - 8px);
    `}
  >
    {children}
  </div>
);

export const CountryFlagHolder = styled('span')`
  display: inline-block;
  padding: 3px;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const CountryFlagHolderInner = styled('span')`
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const CountryFlag = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LangImage = styled('img')`
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  object-fit: none;
`;

const Button = styled('button')`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  background: none;
`;

const ChannelSelector = ({
  acceptChannel,
  channelSelectorId = 'channel-selector'
}) => {
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => {
        let channelFlagCode = selectedChannel.country.code;
        // let channelName = selectedChannel.country.name;

        return acceptChannel ? (
          <Selector
            channels={channels}
            selectedChannel={selectedChannel}
            updateChannel={updateChannel}
            acceptChannel={acceptChannel}
          />
        ) : (
          <Fragment>
            <FlyoutTrigger id="channel-selector">
              {({ showTarget, hideTarget, isOpen }) => (
                <Button onClick={isOpen ? hideTarget : showTarget}>
                  <LangImage
                    src={`https://countryflags.jetshop.io/${channelFlagCode}/flat/32.png`}
                    alt={'Language flag'}
                  />
                  <CarrotIcon />
                </Button>
              )}
            </FlyoutTrigger>
            <FlyoutTarget id={channelSelectorId}>
              {({ hideTarget }) => (
                <Flyout className="channel-select-flyout">
                  <Selector
                    channels={channels}
                    hideTarget={hideTarget}
                    selectedChannel={selectedChannel}
                    updateChannel={updateChannel}
                  />
                </Flyout>
              )}
            </FlyoutTarget>
          </Fragment>
        );
      }}
    </ChannelContext.Consumer>
  );
};

export default ChannelSelector;
