import React, { Component } from 'react';
import styled from 'react-emotion';
import Input from './ui/Input';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import MaxWidth from './Layout/MaxWidth';
import { withRouter } from 'react-router-dom';
import TrendButton from './ui/Button';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  background: #000;
  color: #fff;
`;

const ActionWrapper = styled('section')`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;

  h1 {
    text-align: center;
  }
`;

class LogInPage extends Component {
  render() {
    return (
      <LogInPageMaxWidth>
        <LogInFormProvider redirect="/">
          {({ globalError, isSubmitting, isValid }) => (
            <section>
              <header
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '0.75rem'
                }}
              >
                <h1 style={{ marginBottom: '1rem', fontSize: '1.5rem' }}>
                  {t('Login')}
                </h1>

                {/* <TextLink to={routes.signup.path} className="signup-link">
              {t('Not yet a member? Sign Up')}
            </TextLink> */}
              </header>
              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && <div>{globalError}</div>}

              <ActionWrapper>
                <StyledTrendButton
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                {/* <TextLink to={routes.forgotPassword.path}>
              {t('Forgot password?')}
            </TextLink> */}
              </ActionWrapper>
            </section>
          )}
        </LogInFormProvider>
      </LogInPageMaxWidth>
    );
  }
}
export default withRouter(LogInPage);
