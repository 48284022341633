import styled from 'react-emotion';

export default styled('main')`
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
  padding-bottom: 2rem;
  margin-top: ${({ theme }) => parseInt(theme.sizes.headerHeight) + 62}px;

  ${({ theme }) => theme.above.md} {
    .header-no-margin & {
      margin-top: 0;
    }
  }

  ${({ theme }) => theme.below.md} {
    .header-no-margin & {
      margin-top: 30px;
    }
  }
`;
