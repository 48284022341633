import React from 'react';
import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import { CommitButton } from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from './CustomCheckboxGroup';
import Channels from './Channels';

import styled, { css } from 'react-emotion';

const StyledCommitButton = styled(CommitButton)`
  background: #000;
`;

const SelectorStyle = css`
  border: none;
  width: 295px;
  max-width: 295px;

  .lang-curr-wrapper {
    margin: 0;
  }
`;

const StyledSelectorBody = styled(SelectorBody)`
  padding: 16px;
`;

const CheckboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    text-align: left;

    + div {
      margin-top: 1em;
    }

    & > span {
      display: block;
      margin: 0 0 0.625rem 0;
      font-size: 0.625rem;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #000;

      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }

      & + label {
        margin-top: 0;
      }
    }
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => (
  <ChannelSelector
    channels={channels}
    initialChannel={selectedChannel}
    render={({
      channels,
      languageItems,
      selectedChannel,
      onSelect,
      hasChanged,
      onLanguageSelect
    }) => {
      const Comp = type;

      return (
        <Comp css={SelectorStyle}>
          <StyledSelectorBody>
            {/* <StyledTitle>{t('Choose language')}</StyledTitle> */}
            <Channels
              channels={channels}
              selectedChannel={selectedChannel}
              onSelect={onSelect}
            />
          </StyledSelectorBody>
          {languageItems && languageItems.length > 1 && (
            <LangCurrWrapper className="lang-curr-wrapper">
              <CheckboxGroupContainer>
                <CheckboxGroup
                  selectedItem={selectedChannel.language.name}
                  items={languageItems}
                  handleChange={onLanguageSelect}
                  groupLabel={t('Language')}
                />
              </CheckboxGroupContainer>
            </LangCurrWrapper>
          )}

          {hasChanged ? (
            <StyledCommitButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <StyledCommitButton
              text={t('Close')}
              onClick={() => hideTarget()}
            />
          )}
        </Comp>
      );
    }}
  />
);

export default Selector;
