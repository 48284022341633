import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { ReactComponent as Store } from '../../../svg/Store.svg';
import { ReactComponent as ShoppingCart } from '../../../svg/ShoppingCart.svg';
import { ReactComponent as InstagramIcon } from '../../../svg/InstagramIcon.svg';
import { decodeHTML } from '../../Theme/Utils';

const NavigatorPuffWrapper = styled('div')`
  width: 50%;
  max-width: 600px;
  background: ${({ theme }) => theme.colors.themeGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0 48px;

  &:first-of-type {
    margin-right: 16px;
  }

  ${({ theme }) => theme.below.md} {
    width: 100%;
    margin: 0 16px 16px 16px;
  }
`;

const NavigatorPuffIconWrapper = styled('div')`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigatorPuffStore = styled(Store)`
  position: relative;
  left: 0.5px;
`;

const NavigatorPuffShoppingCart = styled(ShoppingCart)`
  position: relative;
  top: 1px;
  left: 0.5px;
`;

const NavigatorPuffInstagramIcon = styled(InstagramIcon)`
  position: relative;
  left: 0.5px;
`;

const NavigatorPuffHeader = styled('h2')`
  font-size: 1.75rem;
  font-weight: bold;
  margin: 30px 0;

  ${({ theme }) => theme.below.sm} {
    font-size: 1.25rem;
  }
`;

const NavigatorPuffButton = styled('a')`
  text-decoration: none;
  background: none;
  border: 1px solid #000;
  padding: 16px 30px 12px;
  font-size: 1.125rem;
  font-weight: bold;
  transition: ease-in-out 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const LocalNavigatorPuffButton = styled(Link)`
  text-decoration: none;
  background: none;
  border: 1px solid #000;
  padding: 16px 30px 12px;
  font-size: 1.125rem;
  font-weight: bold;
  transition: ease-in-out 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const NavigatorPuff = ({ icon, header, buttonLink, buttonText, buttonUrl }) => {
  const getIcon = icon => {
    switch (icon) {
      case (icon = 'store'):
        return <NavigatorPuffStore />;

      case (icon = 'cart'):
        return <NavigatorPuffShoppingCart />;

      case (icon = 'instagram'):
        return <NavigatorPuffInstagramIcon />;

      default:
        return <NavigatorPuffStore />;
    }
  };

  return (
    <NavigatorPuffWrapper>
      <NavigatorPuffIconWrapper>
        {icon && getIcon(icon)}
      </NavigatorPuffIconWrapper>
      {header && (
        <NavigatorPuffHeader>{decodeHTML(header)}</NavigatorPuffHeader>
      )}
      {buttonText && (
        <>
          {!buttonUrl && buttonLink && (
            <LocalNavigatorPuffButton to={buttonLink}>
              {decodeHTML(buttonText)}
            </LocalNavigatorPuffButton>
          )}
          {!buttonLink && buttonUrl && (
            <NavigatorPuffButton
              href={buttonUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decodeHTML(buttonText)}
            </NavigatorPuffButton>
          )}
        </>
      )}
    </NavigatorPuffWrapper>
  );
};

export default NavigatorPuff;
