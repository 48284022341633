import React, { Component } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import { Link } from 'react-router-dom';
import storesQuery from './StoresQuery.gql';

const Wrapper = styled(MaxWidth)`
  margin: 2rem;
`;

export default class Stores extends Component {
  render() {
    return (
      <Wrapper>
        <Query query={storesQuery}>
          {({ data, loading }) => {
            if (loading) return <Wrapper>Loading…</Wrapper>;
            return (
              <div>
                {data.stores.map(store => (
                  <h1 key={store.id}>
                    <Link to={`/store/${store.id}`}>
                      {store.name} in {store.city}
                    </Link>
                  </h1>
                ))}
              </div>
            );
          }}
        </Query>
      </Wrapper>
    );
  }
}
