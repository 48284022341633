import React from 'react';
import styled from 'react-emotion';
// import { ReactComponent as FacebookLogo } from '../../../svg/FB.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/IG.svg';

const Wrapper = styled('section')`
  margin-right: 0;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  li {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    label {
      margin-left: 1.25rem;
    }
  }

  .row {
    flex-direction: row;
    justify-content: flex-end;
  }

  color: ${({ theme }) => theme.colors.black};
`;

const LogoWrapper = styled('span')`
  width: 1.375rem;
  height: 1.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.below.md} {
    margin-left: 1.25rem;
  }
  svg {
    width: 18px;
    max-height: 80%;

    ${({ theme }) => theme.below.md} {
      width: 25px;
      height: 25px;
      max-height: 100%;
    }
  }
`;

const SocialLinks = ({ showLabel = true, row }) => (
  <Wrapper>
    <ul className={row ? 'row' : null}>
      {/* <li>
        <a href="/">
          <LogoWrapper>
            <FacebookLogo />
          </LogoWrapper>
          {showLabel && <label>Facebook</label>}
        </a>
      </li> */}
      <li>
        <a
          href="https://www.instagram.com/powerbootsoriginalofficial/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <LogoWrapper>
            <InstagramLogo />
          </LogoWrapper>
          {showLabel && <label>Instagram</label>}
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
