import React from 'react';
import styled, { css } from 'react-emotion';

const List = styled('ul')`
  position: absolute;
  top: 0;
  ${props =>
    props.left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
          justify-content: flex-end;
        `};

  //margin: 16px 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 10;
`;

const ListItem = styled('li')`
  list-style: none;
  color: ${({ theme }) => theme.colors.grey};
  display: block;
  padding: 0 8px;
  &:last-of-type {
    padding-right: 0;
  }
`;

export default ({ children, ...rest }) => {
  return (
    <List {...rest}>
      {React.Children.map(children, item => (
        <ListItem fontSize={1}>{item}</ListItem>
      ))}
    </List>
  );
};
