import React from 'react';
import styled, { css } from 'react-emotion';
import { Field, ErrorMessage } from 'formik';
import { InputError, InputPositive } from './InputStatus';

const Input = styled('input')`
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #dedede;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #ffffff;
      color: #808080;
    `}
`;

const Wrapper = styled('div')`
  margin-bottom: 1.5rem;
`;

const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

const Label = styled('label')`
  font-size: 12px;
  margin-bottom: 0.5rem;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #808080;
    `}
`;

const InputWrapper = styled('div')``;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  ...props
}) => (
  <Wrapper>
    <Label disabled={disabled} htmlFor={name}>
      {label}
    </Label>
    <Field
      disabled={disabled}
      id={name}
      name={name}
      render={({ field, form: { touched, errors } }) => (
        <InputWrapper>
          <Input {...props} {...field} />
          {touched[field.name] && errors[field.name] && <InputError />}
          {touched[field.name] && !errors[field.name] && <InputPositive />}
        </InputWrapper>
      )}
    />
    <ErrorMessage name={name} component={ErrorSpan} />
  </Wrapper>
);

export default InputWithLabel;
