import React from 'react';
import t from '@jetshop/intl';
import styled, { css } from 'react-emotion';

const selectedStyle = css`
  color: #000;
`;

const ListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 8px;
  cursor: pointer;

  &:hover {
    font-weight: 500;
    color: #000;
  }

  &:focus {
    outline: none;
  }

  ${props => props.isSelected && selectedStyle};
  &:hover {
    ${selectedStyle};
  }
`;

const LangImage = styled('img')`
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
`;

const ChannelListItem = ({ channel, onSelect, isSelected }) => {
  const FlagImg = channel.name.toLowerCase() + '.png';

  return (
    <ListItem
      isSelected={isSelected}
      tabIndex={0}
      onKeyDown={e => e.key === 'Enter' && onSelect(channel.id)}
      onClick={() => onSelect(channel.id)}
    >
      <span>
        {channel.name}
        {isSelected ? t(' (Chosen)') : ''}
      </span>

      <LangImage
        src={`${
          channel.url
        }/pub_docs/files/Custom_Item_Images/Material/${FlagImg}`}
        alt={'Language flag'}
      />
    </ListItem>
  );
};

export default ChannelListItem;
