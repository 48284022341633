import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const MiniNavWrapper = styled('div')`
  max-height: 0px;
  position: absolute;
  width: 100vw;
  height: 40px;
  background: #fff;
  top: 64px;
  margin-left: -0.75rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-left: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.themeGreyMedium};
  transition: max-height 0.2s cubic-bezier(0.55, 1, 0.55, 1);
  z-index: -1;

  ${({ theme }) => theme.above.sm} {
    margin-left: -2rem;
    justify-content: center;
  }

  a {
    color: ${({ theme }) => theme.colors.themeBlack};
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 1px;
    margin-right: 1.5rem;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .mininav-height & {
    max-height: 40px;
  }
`;

const MiniNav = () => {
  const { selectedChannel } = React.useContext(ChannelContext);
  let routeData = null;

  const routesSE = [
    { id: 'produkter', name: t('Powerboots'), route: '/powerboots-original' },
    { id: 'accessoarer', name: t('Traction aids'), route: '/accessoarer' },
    // { id: 'alpina', name: 'Alpina', route: '/alpina' },
    { id: 'sulman', name: 'Sulman / Alpina', route: '/sulman' }
    // { id: 'om-oss', name: t('About us'), route: '/om-oss' }
  ];

  const routesFI = [
    // { id: 'produkter', name: 'Tuotteet', route: '/powerboots1' },
    // { id: 'accessoarer', name: 'Asusteet', route: '/accessoarer' },
    { id: 'produkter', name: t('Powerboots'), route: '/powerboots1' },
    { id: 'accessoarer', name: t('Traction aids'), route: '/accessoarer' },
    { id: 'sulman', name: 'Sulman / Alpina', route: '/sulman' }
    // { id: 'om-oss', name: 'Meistä', route: '/om-oss' }
    // { id: 'om-oss', name: t('About us'), route: '/om-oss' }
  ];

  if (selectedChannel.id === 1) {
    routeData = routesSE;
  }

  if (selectedChannel.id === 2) {
    routeData = routesFI;
  }

  return (
    <MiniNavWrapper>
      {routeData.map(item => {
        return (
          <Link key={item.id} to={item.route}>
            {item.name}
          </Link>
        );
      })}
    </MiniNavWrapper>
  );
};

export default MiniNav;
