// import SSPEot from './source-sans-pro-v11-latin-regular.eot';
// import SSPWoff from './source-sans-pro-v11-latin-regular.woff';
// import SSPWoff2 from './source-sans-pro-v11-latin-regular.woff2';
// import SSPTtf from './source-sans-pro-v11-latin-regular.ttf';
// import SSPSvg from './source-sans-pro-v11-latin-regular.svg';
// import SSPEotBold from './source-sans-pro-v11-latin-700.eot';
// import SSPWoffBold from './source-sans-pro-v11-latin-700.woff';
// import SSPWoff2Bold from './source-sans-pro-v11-latin-700.woff2';
// import SSPTtfBold from './source-sans-pro-v11-latin-700.ttf';
// import SSPSvgBold from './source-sans-pro-v11-latin-700.svg';

import NimbusSanL_EOT from './NimbusSanL-Reg.eot';
import NimbusSanL_OTF from './NimbusSanL-Reg.otf';
import NimbusSanL_TTF from './NimbusSanL-Reg.ttf';
import NimbusSanL_WOFF from './NimbusSanL-Reg.woff';
import NimbusSanL_WOFF2 from './NimbusSanL-Reg.woff2';
import NimbusSanL_SVG from './NimbusSanL-Reg.svg';

import NimbusSanLBOLD_EOT from './NimbusSanL-Bold.eot';
import NimbusSanLBOLD_OTF from './NimbusSanL-Bold.otf';
import NimbusSanLBOLD_TTF from './NimbusSanL-Bold.ttf';
import NimbusSanLBOLD_WOFF from './NimbusSanL-Bold.woff';
import NimbusSanLBOLD_WOFF2 from './NimbusSanL-Bold.woff2';
import NimbusSanLBOLD_SVG from './NimbusSanL-Bold.svg';

import { injectGlobal } from 'emotion';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return injectGlobal`
  /* Regular font */
  @font-face {
    font-family: 'Nimbus Sans L';
    src: url(${NimbusSanL_EOT}); /* IE9 Compat Mode */
    src: local('Nimbus Sans L Regular'), local('NimbusSanL-Regular'),
        url(${NimbusSanL_WOFF2}) format('woff2'),
        url(${NimbusSanL_WOFF}) format('woff'),
        url(${NimbusSanL_TTF}) format('truetype'),
        url(${NimbusSanL_TTF}) format('opentype'),
        url(${NimbusSanL_SVG}) format('svg'); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Bold font */
  @font-face {
    font-family: 'Nimbus Sans L';
    src: url(${NimbusSanLBOLD_EOT}); /* IE9 Compat Mode */
    src: local('Nimbus Sans L Bold'), local('NimbusSanL-Bold'),
        url(${NimbusSanLBOLD_WOFF}) format('woff2'),
        url(${NimbusSanLBOLD_WOFF2}) format('woff'),
        url(${NimbusSanLBOLD_TTF}) format('truetype'),
        url(${NimbusSanLBOLD_OTF}) format('opentype'),
        url(${NimbusSanLBOLD_SVG}) format('svg'); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

// @font-face {
//   font-family: 'Nimbus Sans L';
//   src: url(${NimbusSanL_OTF});
//   src: url(${NimbusSanL_WOFF2}) format('woff2'),
//        url(${NimbusSanL_WOFF}) format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }
//
// /* Bold font */
// @font-face {
//   font-family: 'Nimbus Sans L';
//   src: url(${NimbusSanLBOLD_OTF});
//   src: url(${NimbusSanLBOLD_WOFF2}) format('woff2'),
//        url(${NimbusSanLBOLD_WOFF}) format('woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }
`;
}
