import React from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';
import { Above } from '@jetshop/ui/Breakpoints';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ReactComponent as LogoKlarnaWhiteSVG } from '../../../svg/KlarnaLogoWhite.svg';
import { ReactComponent as LogoUPSWhite } from '../../../svg/LogoUPS.svg';
import NavigatorPuff from '../../StartPage/StartpageComponents/NavigatorPuff';
import { channelLabels } from '../../Theme';
import MaxWidth from '../MaxWidth';
import FooterLinks from './FooterLinks';
import SocialLinks from './SocialLinks';
import ContactInfo from './ContactInfo';

const PoweredByWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FooterWrapper = styled('section')`
  background: ${({ theme }) => theme.colors.themeBlack};
  ${({ theme }) => theme.above.md} {
    padding-top: ${({ theme }) => theme.space[4]};
    padding-bottom: ${({ theme }) => theme.space[5]};
  }
  ${({ theme }) => theme.below.md} {
    padding-top: ${({ theme }) => theme.space[5]};
    padding-bottom: ${({ theme }) => theme.space[5]};
  }
`;

const FooterMaxWidth = styled(MaxWidth)`
  text-align: left;
  form {
    & > div {
      text-align: center;
    }
    input {
      margin-top: 1rem;
    }
  }

  ${({ theme }) => theme.below.md} {
    form {
      margin-bottom: 0.5rem;
      & > button {
        margin-top: 1rem;
      }
    }
  }

  h2 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h4 {
    color: #fff;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  td,
  li,
  a,
  p {
    padding-bottom: 0.8rem;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.35rem;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  a {
    display: block;
    text-decoration: none;
    :hover {
      //color: ${({ theme }) => theme.colors.black};
      transition: all 0.3s linear;
      text-decoration: underline;
    }
  }

  svg {
      fill: #fff;
  }
`;

const WrapFooterNav = styled('div')`
  ${theme('elements.container')};
  ${({ theme }) => theme.below.md} {
    padding: 0 ${({ theme }) => theme.space[1]};
  }

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${({ theme }) => theme.below.md} {
    flex-direction: column;
  }

  h2 {
    ${({ theme }) => theme.below.md} {
      display: none;
    }
  }

  > section {
    margin: 0 2rem 0 0;
    flex: 0 1 25%;
  }
`;

const FooterColumn = styled('div')`
  flex: 0 1 100%;
  h4 {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  ${({ theme }) => theme.below.md} {
    margin-top: 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const FooterMenu = styled(FooterColumn)`
  ${({ theme }) => theme.below.md} {
    width: 50%;
    margin-bottom: 2rem;
  }
`;

const FooterContact = styled(FooterColumn)`
  ${({ theme }) => theme.below.md} {
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;

const FooterSoMe = styled(FooterColumn)`
  ${({ theme }) => theme.below.md} {
    margin-top: 0;
    width: 50%;
  }
`;

const FooterDescription = styled(FooterColumn)`
  margin-bottom: 4rem;
  ${({ theme }) => theme.below.md} {
    flex: 1 1 100%;
    margin-bottom: 2rem;

    h4 {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    p {
      margin-top: 0;
    }
  }
`;

const FooterLogos = styled(FooterColumn)`
  ${({ theme }) => theme.below.md} {
    flex: 1 1 100%;
    margin-top: 0;

    img {
      max-width: 100%;
      margin-top: 0;
    }
  }

  ${({ theme }) => theme.below.md} {
    width: 100%;
  }
`;

const LogoKlarnaWhite = styled(LogoKlarnaWhiteSVG)`
  width: 77px;
  height: 17px;
`;

const LogoSchenker = () => {
  return (
    <div style={{ width: '140px' }}>
      <Image
        src={
          'https://shepherdofsweden.se/pub_docs/files/Custom_Item_Images/sidfot/logo-db-schenker-white.png'
        }
        aspect={'176:80'}
        sizes={1}
        alt="DB Schenker"
      />
    </div>
  );
};

const LogoUPS = () => {
  return (
    <div style={{ transform: 'scale(.75)' }}>
      <LogoUPSWhite />
    </div>
  );
};

const SoMeRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const KlarnaColumn = styled(FooterColumn)`
  display: flex;
  flex-direction: column;
  flex: 1 0 400px;
`;

const NavigatorPuffContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  ${({ theme }) => theme.below.sm} {
    flex-direction: column;
  }
`;

const Footer = () => {
  const { selectedChannel } = React.useContext(ChannelContext);
  // const isStoreLocatorSE = pathname === '/hitta-butik';

  return (
    <>
      {/* Footer NavigatorPuffs for swedish pages */}
      {selectedChannel.id === 1 && (
        <NavigatorPuffContainer>
          {/* <NavigatorPuff
            icon="store"
            header="Store locator"
            buttonLink="/hitta-butik"
            buttonText={t('Find store')}
          /> */}
          <NavigatorPuff
            icon="cart"
            header={t('Retailer')}
            buttonUrl="https://e-line.lindstromnilsson.se/sv/Login"
            buttonText={t('Login')}
          />
          <NavigatorPuff
            icon="instagram"
            header="Instagram"
            buttonUrl="https://www.instagram.com/powerbootsoriginal/"
            buttonText={t('Follow us on Instagram')}
          />
        </NavigatorPuffContainer>
      )}

      {/* Footer NavigatorPuffs for finnish pages */}
      {selectedChannel.id === 2 && (
        <NavigatorPuffContainer>
          <NavigatorPuff
            icon="cart"
            header={t('Retailer')}
            buttonUrl="https://e-line.lindstromnilsson.se/sv/Login"
            buttonText={'Login'}
          />
          <NavigatorPuff
            icon="instagram"
            header="Instagram"
            buttonUrl="https://www.instagram.com/powerbootsoriginal/"
            buttonText={t('Follow us on Instagram')}
          />
        </NavigatorPuffContainer>
      )}

      <FooterWrapper>
        <FooterMaxWidth>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <WrapFooterNav>
                  <FooterMenu>
                    <h4>Powerboots</h4>
                    <FooterLinks />
                  </FooterMenu>
                  <FooterContact>
                    <h4>{t('Contact')}</h4>
                    <ContactInfo />
                  </FooterContact>
                  <FooterSoMe>
                    <h4>{t(`Let's be social`)}</h4>
                    <SocialLinks showLabel={true} />
                  </FooterSoMe>
                  <KlarnaColumn>
                    <FooterDescription>
                      <h4>
                        {t('Boots and shoes that deliver on their promises')}
                      </h4>
                      <p>
                        {t(
                          'Lindström & Nilsson, founded in 1945, owns the brands Sulman and Powerboots and holds the Swedish agencies for Alpina Outdoor and Stabilicers.'
                        )}
                      </p>
                    </FooterDescription>
                    <FooterLogos>
                      <PoweredByWrapper className={'PoweredByWrapper'}>
                        <LogoKlarnaWhite />
                        {selectedChannel.id === channelLabels['se'] && (
                          <LogoSchenker />
                        )}
                        {selectedChannel.id === channelLabels['fi'] && (
                          <LogoUPS />
                        )}
                      </PoweredByWrapper>
                    </FooterLogos>
                  </KlarnaColumn>
                </WrapFooterNav>
              ) : (
                <WrapFooterNav>
                  <FooterDescription>
                    <h4>
                      {t('Boots and shoes that deliver on their promises')}
                    </h4>
                    <p>
                      {t(
                        'Lindström & Nilsson, founded in 1945, owns the brands Sulman and Powerboots and holds the Swedish agencies for Alpina Outdoor and Stabilicers.'
                      )}
                    </p>
                  </FooterDescription>
                  <SoMeRow>
                    <FooterMenu>
                      <h4>Powerboots</h4>
                      <FooterLinks />
                    </FooterMenu>
                    <FooterSoMe>
                      <SocialLinks showLabel={false} row />
                    </FooterSoMe>
                  </SoMeRow>
                  <FooterContact>
                    <h4>{t('Contact')}</h4>
                    <ContactInfo />
                  </FooterContact>
                  <FooterLogos>
                    <PoweredByWrapper className={'PoweredByWrapper'}>
                      <LogoKlarnaWhite />
                      {selectedChannel.id === channelLabels['se'] && (
                        <LogoSchenker />
                      )}
                      {selectedChannel.id === channelLabels['fi'] && (
                        <LogoUPS />
                      )}
                    </PoweredByWrapper>
                  </FooterLogos>
                </WrapFooterNav>
              )
            }
          </Above>
        </FooterMaxWidth>
      </FooterWrapper>
    </>
  );
};

export default Footer;
