export const routeSE = [
  {
    hasSubcategories: false,
    id: 'produkter',
    // name: 'Produkter',
    name: 'Powerboots',
    primaryRoute: { path: '/powerboots-original' },
    subcategories: []
  },
  {
    hasSubcategories: false,
    id: 'accessoarer',
    // name: 'Accessoarer',
    name: 'Traction aids',
    primaryRoute: { path: '/accessoarer' },
    subcategories: []
  },
  {
    hasSubcategories: false,
    id: 'sulman',
    name: 'Sulman / Alpina',
    primaryRoute: { path: '/sulman' },
    subcategories: []
    // {
    //   id: 'sulman',
    //   name: 'Sulman',
    //   primaryRoute: { path: '/sulman' },
    //   subcategories: []
    // },
    // {
    //   id: 'alpina',
    //   name: 'Alpina',
    //   primaryRoute: { path: '/alpina' },
    //   subcategories: []
    // }
  }
];

export const routeFI = [
  {
    hasSubcategories: false,
    id: 'produkter',
    // name: 'Tuotteet',
    name: 'Powerboots',
    primaryRoute: { path: '/powerboots1' },
    subcategories: []
  },
  {
    hasSubcategories: false,
    id: 'accessoarer',
    // name: 'Asusteet',
    name: 'Traction aids',
    primaryRoute: { path: '/asusteet' },
    subcategories: []
  },
  {
    hasSubcategories: false,
    id: 'sulman',
    // name: 'Tuotemerkit',
    name: 'Sulman / Alpina',
    primaryRoute: { path: '/sulman' },
    subcategories: [
      // {
      //   id: 'sulman',
      //   name: 'Sulman',
      //   primaryRoute: { path: '/sulman' },
      //   subcategories: []
      // }
    ]
  }
];
