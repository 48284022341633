import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';

const borderColor = '#e8e8e8';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: auto;
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  //width: fit-content;
  width: 100%;
  margin: 0 auto;
  //min-width: 50vw;

  // position: relative;
  //
  // &:before {
  //   content: '';
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: #000;
  //   opacity: .2;
  // }
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
  background: #fff;
  //border: 1px solid ${borderColor};
  //border-width: 1px 0 1px 0;
  border-top: 2px solid #000;
  padding: 1rem 0;
  overflow: hidden;
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  ${({ theme }) => theme.elements.fwContainer}
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  z-index: 10;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    padding: 0 1rem;
  }
`;

const NoThirdTierItems = styled('div')`
  display: flex;
  //flex: 0 1 15%;
  flex: 0 1 auto;
  justify-content: flex-start;
  margin-right: 3rem;
  &:last-of-type {
    margin-right: 0;
  }
  h2 {
    padding: 0 0.5rem;
  }
`;

const SubMenuWrapper = ({
  pose,
  activeCategory,
  closeNav,
  client,
  data,
  hideMenuBackground,
  setActiveCategory
}) => {
  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper>
        <ContentFit>
          <MaxWidth>
            {activeCategory &&
              activeCategory.subcategories.map(cat =>
                cat.subcategories.length > 0 ? (
                  <SubMenuSection
                    key={cat.id}
                    heading={cat}
                    categories={cat.subcategories}
                    onSelect={closeNav}
                  />
                ) : (
                  <NoThirdTierItems key={cat.id}>
                    <h2>
                      {/*<CategoryLink category={cat} onClick={closeNav}>*/}
                      <CategoryLink
                        category={cat}
                        onClick={() => {
                          hideMenuBackground();
                          setActiveCategory('');
                        }}
                      >
                        {cat.name}
                      </CategoryLink>
                    </h2>
                  </NoThirdTierItems>
                )
              )}
          </MaxWidth>
        </ContentFit>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
