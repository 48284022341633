import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as IconPhone } from '../../../svg/Phone.svg';
import { ReactComponent as IconAt } from '../../../svg/At.svg';
import { ReactComponent as IconMail } from '../../../svg/Mail.svg';
import { ReactComponent as IconMarker } from '../../../svg/Marker.svg';

// {
//   icon: IconAt,
//   info: (
//     <a href="mailto:mailto:shop@lindstromnilsson.se">
//       shop@lindstromnilsson.se
//     </a>
//   ),
//   id: 2
// },

const contactInfo = [
  {
    icon: IconPhone,
    info: '+46 (0) 325 661 380',
    id: 1
  },
  {
    icon: IconAt,
    info: <a href="mailto:shop@powerboots.se">shop@powerboots.se</a>,
    id: 2
  },
  {
    icon: IconMail,
    info: <a href="mailto:shop@powerboots.se">shop@powerboots.se</a>,
    id: 3
  },
  {
    icon: IconMarker,
    info: (
      <p>
        Lindström & Nilsson AB
        <br />
        Nylännesgatan 8
        <br />
        512 53 Svenljunga, Sweden
      </p>
    ),
    id: 4
  }
];

const TableRow = ({ row }) => (
  <tr>
    <td key={row.icon}>{<row.icon />}</td>
    <td key={row.info}>{row.info}</td>
  </tr>
);

const Table = ({ data }) => {
  return (
    <table>
      <tbody>
        {data.map((row, i) => {
          return <TableRow key={row.id} row={row} />;
        })}
      </tbody>
    </table>
  );
};

const Address = styled('address')`
  td {
    vertical-align: top;
    &:last-of-type {
      padding-left: 1.25rem;
      color: ${theme('colors.greyDark')};
    }
    svg {
      width: 15px;
      height: 15px;
      position: relative;
      top: 3px;
    }
  }
`;

const ContactInfo = () => (
  <Address>
    <Table data={contactInfo} />
  </Address>
);

export default ContactInfo;
