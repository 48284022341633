import React from 'react';
import styled, { css } from 'react-emotion';
import Accordion, { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import t from '@jetshop/intl';
// import getCategoriesByLevel from './__util__/getCategoriesByLevel';
// import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
// import hasChildCategories from './__util__/hasChildCategories';
import { ReactComponent as ArrowSVG } from '../../../../svg/Arrow.svg';

const activeCategoryLink = css`
  font-weight: 600;
`;

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const ArrowIcon = styled(ArrowSVG)`
  width: 36px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 7px;
  padding: 8px 6px;
`;

// const ArrowIcon = styled(ArrowSVG)`
// `;

const ItemWrapper = styled('div')`
  position: relative;
`;

const CatBackButton = styled('button')`
  margin-left: 12px;
  margin-bottom: 1rem;
  background: transparent;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: left;
  > svg {
    width: 18px;
    height: 12px;
    position: relative;
    bottom: 1px;
    margin-right: 0.75rem;
    transform: rotate(90deg);
  }
`;

const Wrapper = styled('div')`
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -10px;
    left: 100%;
    padding-top: 10px;
    background: #fff;
    transition: left ease-in-out 0.2s;
    z-index: 2;
  }
  > ${ItemWrapper} {
    > a {
      display: block;
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 1.125rem;
      font-weight: bold;
      color: black;
      text-decoration: none;
    }
  }

  &[class*='activeCategoryLink'],
  &[data-open] {
    & > div:nth-child(2) {
      left: 0;
      height: 100% !important;
    }
  }
`;

const MobileCategories = ({ categories, closeMenu, parentCategory = null }) => {
  // This component is called recursively. On the first run through, we set up all categories
  // and their subcategories. On subsequent run-throughs (with parentCategory set), we set up
  // the accordion for just that parent Category and its children.

  // const categoryLevel = parentCategory
  // ? [parentCategory]
  //   : getCategoriesByLevel(categories, 1);

  return (
    <Accordion single>
      {({ handleClick, openIndexes }) =>
        categories.map((category, i) => (
          <Wrapper
            key={category.id}
            className={openIndexes.includes(i) ? activeCategoryLink : null}
            data-open={openIndexes.includes(i) ? activeCategoryLink : null}
          >
            {/* // Use AccordionContent */}
            <ItemWrapper>
              <CategoryLink
                className={openIndexes.includes(i) ? activeCategoryLink : null}
                onClick={e => {
                  if (category.id === 'varumarken') {
                    handleClickAndPreventDefault(e, handleClick, i);
                  } else {
                    closeMenu();
                  }
                }}
                category={category}
              >
                {t(category.name)}
              </CategoryLink>
              {category.hasSubcategories &&
                category.subcategories.length > 0 && (
                  <ArrowIcon
                    onClick={e =>
                      handleClickAndPreventDefault(e, handleClick, i)
                    }
                  />
                )}
            </ItemWrapper>
            {category.hasSubcategories && category.subcategories.length > 0 && (
              <AccordionContent isOpen={openIndexes.includes(i)}>
                <CatBackButton onClick={() => handleClick(i)}>
                  <ArrowSVG />
                  {t(category.name)}
                </CatBackButton>
                <SubCategories
                  categories={category.subcategories}
                  category={category}
                  closeMenu={closeMenu}
                />
              </AccordionContent>
            )}
          </Wrapper>
        ))
      }
    </Accordion>
  );
};

const SubCategories = ({ categories, category, closeMenu }) => {
  return categories.map((childCat, i) => {
    return (
      <CategoryLink onClick={closeMenu} category={childCat} key={i}>
        {childCat.name}
      </CategoryLink>
    );
  });

  // return getCategoriesUnderParent(categories, category.id).map((childCat, i) =>
  //   // Recursively load deeper category levels
  //   hasChildCategories(categories, childCat) ? (
  //     <MobileCategories
  //       key={i}
  //       categories={categories}
  //       closeMenu={closeMenu}
  //       parentCategory={childCat}
  //     />
  //   ) : (
  //     // Or just display the category link
  //     <CategoryLink onClick={closeMenu} category={childCat} key={i}>
  //       {childCat.name}
  //       {console.log('childcat2', childCat)}
  //     </CategoryLink>
  //   )
  // );
};

export default MobileCategories;
