import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { Component } from 'react';
import Helmet from 'react-helmet-async';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import LogInPage from './LogInPage';
import NavTreePage from './NavigationTree/NavTreePage';
import NotFound from './NotFoundPage';
import Store from './Store/Store';
import Stores from './Stores/Stores';
import routeQuery from './RouteQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import { fonts } from './Theme';

//import { GlobalFilters } from '@jetshop/core/components/Filters/GlobalFilters';

const LoadableStartPage = Loadable({
  loader: () => import('./StartPage/StartPage'),
  loading: LoadingPage
});

const LoadableProductPage = Loadable({
  loader: () => import('./ProductPage/ProductPage'),
  loading: LoadingPage
});

const LoadableCategoryPage = Loadable({
  loader: () => import('./CategoryPage/CategoryPage'),
  loading: LoadingPage
});

const LoadableContentPage = Loadable({
  loader: () => import('./ContentPage/ContentPage'),
  loading: LoadingPage
});

const LoadableSearchPage = Loadable({
  loader: () => import('./SearchPage/SearchPage'),
  loading: LoadingPage
});

class Shop extends Component {
  render() {
    return (
      <GenericError>
        <ModalProvider>
          <Container>
            <LoadingBar />
            <CustomFont primaryFont={fonts.primary} injectCss={loadFontCss} />
            <Helmet
              titleTemplate="%s - Powerboots Original"
              defaultTitle="Powerboots Original"
            />
            <Header />
            <Content>
              {/*<GlobalFilters defaultFilters={ {listFilters: [{id: 'color', values: ['Red', 'Blue'] }] } }>*/}
              {/*<GlobalFilters defaultFilters={ {listFilters: [{id: 'gender', values: ['Unisex'] }] } }>*/}

              <Switch>
                <Route exact path="/" component={LoadableStartPage} />
                <Route path="/search" component={LoadableSearchPage} />
                <Route path="/login" component={LogInPage} />
                <Route path="/stores" component={Stores} />
                <Route path="/store/:id" component={Store} />
                <Route path="/tree" component={NavTreePage} />
                <Route
                  path="/preview"
                  component={props => (
                    <PreviewRoute
                      productPage={LoadableProductPage}
                      productQuery={ProductPreviewQuery}
                      categoryQuery={CategoryPreviewQuery}
                      categoryPage={LoadableCategoryPage}
                      StartPage={LoadableStartPage}
                      {...props}
                    />
                  )}
                />
                <DynamicRoute
                  routeQuery={routeQuery}
                  productPage={LoadableProductPage}
                  categoryPage={LoadableCategoryPage}
                  contentPage={LoadableContentPage}
                  notFoundPage={NotFound}
                  LoadingPage={LoadingPage}
                />
              </Switch>
              {/*</GlobalFilters>*/}
            </Content>
            <Footer />
            <ModalRoot />
            <ScrollRestorationHandler />
          </Container>
        </ModalProvider>
      </GenericError>
    );
  }
}

export default Shop;
