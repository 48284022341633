import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'lindstromnilsson',
    //shopid: process.env.REACT_APP_SHOP_ID || 'flight-test',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery
  },
  nostoAccountID: process.env.REACT_APP_NOSTO_ACCOUNT_ID,
  addwishAccountID: process.env.REACT_APP_ADDWISH_ACCOUNT_ID || '',
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || '',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || '',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: { style: 'currency', currency: 'SEK' },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: []
};
