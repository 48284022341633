import { pagesQuery } from '@jetshop/core/data/queries';
import get from 'lodash.get';
import React, { useContext } from 'react';
import t from 'format-message';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const FooterLinks = () => {
  const { selectedChannel } = useContext(ChannelContext);

  let aboutPage = '/om-oss';
  if (selectedChannel.id === 2) {
    aboutPage = '/meista';
  }

  return (
    <Query query={pagesQuery}>
      {({ data }) => {
        const pages = get(data, 'pages');

        return pages ? (
          <section>
            <ul>
              {pages.map(page => (
                <li key={page.id}>
                  <Link key={page.id} to={page.primaryRoute.path}>
                    {page.name}
                  </Link>
                </li>
              ))}
              <li>
                <Link to={aboutPage}>{t('About us')}</Link>
              </li>
              <li>
                <a
                  href="https://e-line.lindstromnilsson.se/sv/Login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ÅF login
                </a>
              </li>
            </ul>
          </section>
        ) : null;
      }}
    </Query>
  );
};

export default FooterLinks;
