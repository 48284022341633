import t from '@jetshop/intl';
import React, { Fragment, Component } from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { ReactComponent as CloseSVG } from '../../../svg/Close.svg';
import { ReactComponent as LogoMobileSVG } from '../../../svg/PowerbootsLogoColor.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import MobileCategories from './Categories/MobileCategories';
import TopNav from './TopNav';
import ChannelSelector from './ChannelSelector';
import FooterLinks from '../Footer/FooterLinks';
import MiniNav from './MiniNav';
import * as NavRoutes from './ThreeTierMenu/MenuRoutes';
import ChannelContext from '@jetshop/core/components/ChannelContext';
//import MaxWidth from '../MaxWidth';

const Divider = styled('hr')`
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  margin-right: 12px;
`;

const MobileMenuModal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  a {
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    color: black;
    text-decoration: none;
  }
`;

// const MenuTitle = styled('h1')`
//   font-weight: bold;
//   font-size: 16px;
//   margin: 16px;
// `;

const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  > span {
    margin-top: 5px;
  }
  > svg {
    height: 18px;
  }
`;

const SearchButton = styled(MenuButton)`
  align-items: center;
  outline: none;
`;

const Logo = styled(LogoMobileSVG)`
  fill: ${({ theme }) => theme.colors.themeBlack};
  width: 47px;
  height: 32px;
`;

const MenuTop = styled('div')`
  height: 58px;
  position: relative;
  border-bottom: 2px solid #000;
`;

const MenuCatBody = styled('div')`
  position: relative;
  height: 100%;
  margin: ${({ theme }) => theme.space[2]} 0;
`;

const FooterCloseButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.mobileCloseButtonHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.themeGrey};
  > svg {
    margin-left: 1.5rem;
    width: 12px;
    height: 12px;
    align-self: center;
  }
`;

const Close = styled(CloseSVG)``;

const MenuClose = styled(CloseSVG)`
  width: 20px;
  height: 20px;
`;

const StyledTopNav = styled(TopNav)`
  > li {
    padding: 0;
    > a {
      margin: 0;
    }
  }
`;

const MenuHeaderWrapper = styled('div')`
  padding: 0 ${({ theme }) => theme.space[2]};
  height: 100%;
  > nav {
    height: 100%;
    position: relative;
  }
`;

const MenuLinksWrapper = styled('div')`
  > section {
    > ul {
      margin-top: 1.5rem;
      > li {
        margin-bottom: 0.5rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

class MainNavMobile extends Component {
  state = {
    mobileMenuOpen: false,
    queryData: null
  };
  static contextType = ChannelContext;
  static queryData = null;

  // renderMobileMenu = () => {
  //   const { mobileMenuOpen } = this.state;
  //   const { result } = this.props;
  //   const { data } = result;
  //
  //
  //
  //   if (!(data && data.categories)) return null;
  //
  //   return (
  //
  //   );
  // };

  openMobileMenu = () => {
    this.setState({ mobileMenuOpen: true });
  };
  closeMobileMenu = () => {
    this.setState({ mobileMenuOpen: false });
  };

  componentDidMount() {
    const selectedChannel = this.context;
    this.queryData = NavRoutes.routeSE;

    if (selectedChannel.selectedChannel.id === 1) {
      this.queryData = NavRoutes.routeSE;
    }

    if (selectedChannel.selectedChannel.id === 2) {
      this.queryData = NavRoutes.routeFI;
    }
  }

  render() {
    // const {
    //   setSearchOpen,
    //   closeMobileMenu,
    //   openMobileMenu,
    //   renderMobileMenu,
    //   renderMobile = false
    // } = this.props;

    // const {
    //   renderMobile = false,
    // } = this.props;
    //const { result } = this.props;

    // const result = this.props;
    // const data = result.result ? result.result.data : null;

    const data = this.queryData;

    const CondWrapper = this.props.openMenu ? MenuHeaderWrapper : Fragment;

    return (
      <Fragment>
        <CondWrapper>
          <nav>
            <Fragment>
              <StyledTopNav left>
                <Link onClick={this.props.closeMobileMenu} to="/">
                  <Logo />
                </Link>
              </StyledTopNav>
              <TopNav right>
                <div>
                  <ChannelSelector />
                </div>
                <SearchButton onClick={this.props.setSearchOpen}>
                  <Search />
                </SearchButton>
                <CartButton />
                {this.props.openMenu ? (
                  <MenuButton onClick={this.props.closeMobileMenu}>
                    <MenuClose />
                  </MenuButton>
                ) : (
                  <MenuButton onClick={this.openMobileMenu}>
                    <Menu />
                  </MenuButton>
                )}
              </TopNav>
              <MiniNav />
            </Fragment>
            <CartFlyout />
          </nav>
        </CondWrapper>

        {data && (
          <MobileMenuModal
            isOpen={this.state.mobileMenuOpen}
            onRequestClose={this.closeMobileMenu}
            className="MobileModal__Content"
            overlayClassName="MobileModal__Overlay"
            closeTimeoutMS={500}
          >
            <MenuTop>
              <MainNavMobile
                closeMobileMenu={this.closeMobileMenu}
                openMobileMenu={this.openMobileMenu}
                openMenu={this.state.mobileMenuOpen}
              />
            </MenuTop>

            <MenuCatBody>
              <MobileCategories
                categories={data}
                closeMenu={this.closeMobileMenu}
              />
              <div>
                <Divider />
              </div>
              <MenuLinksWrapper>
                <FooterLinks />
              </MenuLinksWrapper>
            </MenuCatBody>
            <FooterCloseButton onClick={this.closeMobileMenu}>
              <span>{t('Close')}</span> <Close />
            </FooterCloseButton>
          </MobileMenuModal>
        )}
      </Fragment>
    );
  }
}

export default MainNavMobile;
