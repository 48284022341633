import Analytics from '@jetshop/core/analytics/Analytics';
import { trackCartCheckout } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
//import { Price } from '@jetshop/ui/Price';
import { default as Price } from '../Theme/Price';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React from 'react';
import styled from 'react-emotion';
import Button from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout/FlyoutTrigger';
import { ReactComponent as CloseSVG } from '../../svg/Close.svg';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Flyout = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding-bottom: ${({ theme }) => theme.space[2]};
  color: ${theme('colors.black')};

  ${({ theme }) => theme.above.sm} {
    height: auto;
    max-height: 70vh;
    min-width: 19rem;
    max-width: 820px;
    position: absolute;
    top: 12vh;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0 auto;
    padding: ${({ theme }) => theme.space[3]} 0;
  }
`;

const LightText = styled('span')`
  font-size: 1rem;
  font-weight: normal;
`;

const ItemsWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

// const Header = styled('header')`
//   text-align: center;
//   h2 {
//     font-weight: 600;
//     font-family: ${theme('fontFamilies.heavy')};
//   }
//   ${LightText} {
//     display: block;
//   }
//   padding: 1rem;
//   border-bottom: 1px solid #e8e8e8;
// `;
//
// const ItemCount = ({ count }) => (
//   <LightText>
//     {t(
//       `{
//         count, plural,
//         =0 {}
//         one {1 item}
//         other {{count} items}
//             }`,
//       { count }
//     )}
//   </LightText>
// );

const CloseFlyoutButton = styled(CloseSVG)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 50%;
  background: #fff;
  padding: 12px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
`;

const CloseDrawerButton = styled('button')`
  background: none;
  appearance: none;
  padding: 0;
  outline: none;
  text-decoration: underline;
  /* position: absolute;
  top: 1rem;
  left: 0; */
  ${({ theme }) => theme.below.sm} {
    display: block;
    position: relative;
    top: 0;
    margin: 0 auto;
    margin-top: 1rem;
  }
`;

const ButtonsWrapper = styled('div')`
  position: relative;
`;

const Summary = styled('section')`
  background: #fff;
  padding: 0 ${({ theme }) => theme.space[5]};
  ${({ theme }) => theme.below.sm} {
    padding: 0 ${({ theme }) => theme.space[2]};
  }
  a {
    width: 100%;
    color: white;
    max-width: 296px;
    margin: 0 auto;
    text-decoration: none;
    :hover {
      color: white;
    }
  }
`;

const SummmaryInfoInner = styled('div')`
  width: 100%;
  max-width: 240px;
  float: right;
  ${({ theme }) => theme.below.md} {
    float: none;
    max-width: none;
  }
`;

const SummmaryInfo = styled('div')`
  overflow: hidden;
  padding: ${({ theme }) => theme.space[3]} 0;
  ${({ theme }) => theme.below.sm} {
    padding: ${({ theme }) => theme.space[2]} 0;
  }
`;

const DeliverySummary = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const TotalSummary = styled('div')`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  > label {
    text-transform: uppercase;
  }
`;

const Checkout = styled(Button)`
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.themeGreen};
`;

const TH = styled('div')`
  //display: table-cell;
`;

const CloseIcon = styled(CloseSVG)`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 1rem;
`;

const MobileHeading = styled('div')`
  display: table-row;

  > h3 {
    padding-left: ${({ theme }) => theme.space[2]};
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  > button {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    text-decoration: none;
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

const CartItems = styled('div')`
  //display: table;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  ${({ theme }) => theme.below.sm} {
    padding: ${({ theme }) => theme.space[2]} 0;
  }
  ${TH} {
    &:first-of-type {
      padding-left: ${({ theme }) => theme.space[5]};
      ${({ theme }) => theme.below.sm} {
        padding-left: ${({ theme }) => theme.space[2]};
      }
    }
    &:last-of-type {
      padding-right: ${({ theme }) => theme.space[5]};
      ${({ theme }) => theme.below.sm} {
        padding-right: ${({ theme }) => theme.space[2]};
      }
    }
  }
`;

const CartItemsHeadings = styled('div')`
  //display: table-row;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  h3 {
    text-transform: uppercase;
  }
  ${TH} {
    padding-top: ${({ theme }) => theme.space[1]};
    padding-bottom: ${({ theme }) => theme.space[2]};
    text-align: left;
  }
`;

const CartFlyoutView = ({ cart, modal, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  //const itemCount = get(cart, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(cart, 'data.cart.externalCheckoutUrl');
  const cartData = get(cart, 'data.cart');
  const totalCartVAT = cartData.productTotal.incVat;

  // const {
  //   hasMetLimit,
  //   untilLimit,
  //   freeShippingConfigured
  // } = useFreeShippingCheck({
  //   totalCartVAT
  // });
  //
  // if (hasMetLimit) {
  // console.log('limit reached');
  // }

  const freightCosts = {
    SE: {
      cost: 49,
      label: ' kr'
    },
    FI: {
      cost: 6.5,
      label: ' €'
    }
  };

  //<ItemCount count={itemCount} />
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <Flyout {...rest}>
          <Above breakpoint="md">
            {matches =>
              matches && (
                <FlyoutTrigger id="cart-flyout">
                  {flyout => (
                    <CloseFlyoutButton
                      onClick={
                        flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                      }
                    >
                      {t('Back to shop')}
                    </CloseFlyoutButton>
                  )}
                </FlyoutTrigger>
              )
            }
          </Above>
          <CartItems>
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <CartItemsHeadings>
                    <TH>
                      <h3>{t('Your cart')}</h3>
                    </TH>
                    <TH>{t('Product')}</TH>
                    <TH>{t('Quantity')}</TH>
                    <TH>{t('Price')}</TH>
                    <TH />
                  </CartItemsHeadings>
                ) : (
                  <MobileHeading>
                    <h3>{t('Your cart')}</h3>
                    <DrawerTrigger id="cart-drawer">
                      {flyout => (
                        <CloseDrawerButton
                          onClick={
                            flyout.isOpen
                              ? flyout.hideTarget
                              : flyout.showTarget
                          }
                        >
                          {t('Close')}
                          <CloseIcon />
                        </CloseDrawerButton>
                      )}
                    </DrawerTrigger>
                  </MobileHeading>
                )
              }
            </Above>
            <ItemsWrapper>
              {items.map(item => (
                <CartItem item={item} key={item.id} />
              ))}
            </ItemsWrapper>
          </CartItems>

          <Summary>
            <SummmaryInfo>
              <SummmaryInfoInner>
                <DeliverySummary>
                  <LightText>{t('Delivery')}</LightText>
                  <LightText>
                    {totalCartVAT <
                    selectedChannel.countrySettings.shipping.freeShippingLimit
                      .incVat
                      ? `+ ${freightCosts[selectedChannel.name].cost}${
                          freightCosts[selectedChannel.name].label
                        }`
                      : t('Free for your order')}
                  </LightText>
                </DeliverySummary>

                <TotalSummary>
                  <label>{t('Total')}</label>
                  {cart.data && cart.data.cart && (
                    <Price
                      price={cart.data.cart.productTotal}
                      freightCost={
                        totalCartVAT <
                        selectedChannel.countrySettings.shipping
                          .freeShippingLimit.incVat
                          ? freightCosts[selectedChannel.name].cost
                          : 0
                      }
                    />
                  )}
                </TotalSummary>
              </SummmaryInfoInner>
            </SummmaryInfo>

            {/*
  <FlyoutTrigger id="cart-flyout">
    {flyout => (
      <ContinueShoppingButton
        onClick={flyout.isOpen ? flyout.hideTarget : flyout.showTarget}
      >
        {t('Back to shop flyout')}
      </ContinueShoppingButton>
    )}
  </FlyoutTrigger>
  */}

            <ButtonsWrapper>
              {checkoutUrl && (
                <Analytics>
                  {track => (
                    <Checkout
                      anchor={true}
                      href={checkoutUrl}
                      onClick={() => {
                        track(
                          trackCartCheckout({
                            cart: cart.data && cart.data.cart
                          })
                        );
                      }}
                    >
                      {t('Check out')}
                    </Checkout>
                  )}
                </Analytics>
              )}

              <Above breakpoint="md">
                {matches =>
                  matches ? (
                    <FlyoutTrigger id="cart-flyout">
                      {flyout => (
                        <CloseDrawerButton
                          onClick={
                            flyout.isOpen
                              ? flyout.hideTarget
                              : flyout.showTarget
                          }
                        >
                          {t('Back to shop')}
                        </CloseDrawerButton>
                      )}
                    </FlyoutTrigger>
                  ) : (
                    <DrawerTrigger id="cart-drawer">
                      {flyout => (
                        <CloseDrawerButton
                          onClick={
                            flyout.isOpen
                              ? flyout.hideTarget
                              : flyout.showTarget
                          }
                        >
                          {t('Back to shop')}
                        </CloseDrawerButton>
                      )}
                    </DrawerTrigger>
                  )
                }
              </Above>
            </ButtonsWrapper>
          </Summary>
        </Flyout>
      )}
    </ChannelContext.Consumer>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {cart =>
      cart.data && cart.data.cart ? (
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <FlyoutTarget id="cart-flyout">
                {flyout => (
                  <CartFlyoutView modal={flyout} cart={cart} {...props} />
                )}
              </FlyoutTarget>
            ) : (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer size="715" isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} cart={cart} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )
          }
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
