import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';
import { baseTheme } from '@jetshop/ui/BaseTheme';

/** To define your own breakpoints, uncomment and edit the below. */
// import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';
export const labels = ['xs', 'sm', 'md', 'lg', 'bigly'];
// const breakpoints = ['20rem', '40rem', '50rem', '64rem', '90rem'];

// Object.assign(baseTheme, {
//   labels,
//   breakpoints,
//   ...createBreakpointHelpers(labels, breakpoints)
// });

const colors = {
  ...baseTheme.colors,
  blue: '#2f80ed',
  themeBlack: '#222222',
  themeBlackFade: '#3d3d3d',
  themeGreen: '#38BA6A',
  themeGreenLight: '#5ECB88',
  themeGrey: '#F7F7F7',
  themeGreyLight: '#e8e8e8',
  themeGreyMedium: '#ACACAC',
  themeGreyMediumDark: '#5C5C5C',
  themeGreyDark: '#888888',
  themeOrange: '#FD9346',
  themeRed: '#D10020',
  themeWhite: '#ffffff',
  alpinaRed: '#ED1C24',
  wildSand: '#F4F4F4'
};

export const fonts = {
  primary: 'Nimbus Sans L'
};

const sizes = {
  topBarHeight: '49px',
  headerHeight: '58px',
  largePuffHeight: '410px'
};

const typography = {
  h1: `
  font-size: 2.875rem;
  line-height: 3.4375rem;
  @media screen and (max-width: 49.99rem) {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
  `,
  h2: `1.5rem;`,
  h3: '1.125rem;'
};

const borderHeading = `
  position: relative;
  font-weight: bold;
  font-size: ${typography.h2};
  text-transform: uppercase;
  &:after {
    content: '';
    width: 68px;
    height: 7px;
    background: ${colors.themeBlack};
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5rem;
    margin: 0 auto;
  }
`;

const elements = {
  button: `
    padding: .65em 1.2em;
    border: 1px solid #fff;
    font-size: 1.125rem;
    color: #fff;
    text-decoration: none;
  `,
  fwContainer: `
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  `
};

// Use https://meowni.ca/font-style-matcher/ to determine fallback styles that
// most closely match your fontFamily above
const fallbackStyles = `
  font-size: 16px;
  line-height: 1.5rem;
  letter-spacing: -0.65px;
  word-spacing: 0.75px;
  font-weight: 400;
  visibility: visible;
`;

const mobileCloseButtonHeight = '48px';

injectGlobal`
  body {
    ${fallbackStyles};
  }

  button, input {
    outline: none;
  }

  .MobileModal__Overlay {
    width: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 10;
    transition: left ease-in-out .2s;
    background: #fff;
  }

  .MobileModal__Content {
    height: calc(100vh - ${mobileCloseButtonHeight});
  }

  .MobileModal__Overlay.ReactModal__Overlay--after-open {
    left: 0;
    height: 100%;
  }

  .MobileModal__Overlay.ReactModal__Overlay--before-close{
    left: 100%;
  }

  /*
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    color: ${colors.themeBlack};
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  h1 {
    font-size: 2.875rem;
  }
  h2 {
  }
  h3 {
  }
  h4 {

  }
  h5 {

  }
`;

export const channelLabels = {
  se: 1,
  fi: 2
};

const theme = {
  ...baseTheme,
  borderHeading,
  colors,
  elements,
  fonts,
  mobileCloseButtonHeight,
  typography,
  sizes
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node
};
export default Theme;
