import { default as React, Component } from 'react';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import ChannelSelector from './ChannelSelector';
import MenuContainer from './ThreeTierMenu/MenuContainer';

import styled from 'react-emotion';
import { ReactComponent as LogoSVG } from '../../../svg/PowerbootsLogoColor.svg';
import { ReactComponent as SearchSVG } from '../../../svg/Search.svg';
import TopNav from './TopNav';
import MaxWidth from '../../Layout/MaxWidth';

const Logo = styled(LogoSVG)`
  width: 47px;
  height: 32px;
  position: relative;
  right: 6px;
  fill: ${({ theme }) => theme.colors.themeBlack};
  ${({ theme }) => theme.above.md} {
    .start & {
      display: none;
    }
  }
`;

const WhiteLogo = styled(LogoSVG)`
  display: none;

  ${({ theme }) => theme.above.md} {
    .start & {
      display: block;
      width: 93px;
      height: 64px;
      position: relative;
      top: 16px;
      right: 6px;
    }
  }
`;

const SearchButton = styled('button')`
  outline: none;
  background: none;
`;

const SearchIcon = styled(SearchSVG)`
  ${({ theme }) => theme.above.md} {
    .start & {
      fill: #fff;
    }
  }
`;

class MainMenu extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.searchIsOpen !== nextProps.searchIsOpen) return true;
    if (this.props.categories.loading !== nextProps.categories.loading)
      return true;

    return false;
  }
  render() {
    //const { searchIsOpen, setSearchOpen, categories } = this.props;
    const { setSearchOpen, categories } = this.props;
    return (
      <nav>
        <MaxWidth>
          <TopNav left>
            <Link to={'/'}>
              <Logo />
              <WhiteLogo />
            </Link>
          </TopNav>
          <MenuContainer queryData={categories.data} />
          <TopNav right>
            <div>
              <ChannelSelector />
            </div>
            <SearchButton onClick={setSearchOpen}>
              <SearchIcon />
            </SearchButton>
            <CartButton />
          </TopNav>
          {/*
          <TopNav left>
            <Link to="/">{t('Customer Service')}</Link>
            <ChannelSelector />
            <Link to="/">{t('Find Store')}</Link>
          </TopNav>
          <TopNav right>
            {searchIsOpen || (
              <button onClick={setSearchOpen}>{t('Search')}</button>
            )}
            <Link to="/">{t('My page')}</Link>
            <Link to="/">{t('Saved')}</Link>
            <CartButton />
          </TopNav>
          */}
          <CartFlyout />
        </MaxWidth>
      </nav>
    );
  }
}

export default MainMenu;
